@import url('https://rsms.me/inter/inter.css');
@import 'material-icons/iconfont/material-icons.css';

@import './scss/colors';
@import './scss/theme';

html, body { height: 100%; }
body { margin: 0; font-family: "Inter","Helvetica Neue", sans-serif; }

a {
  color: $color-black;
  font-weight: 600;
  text-decoration: none;
}

button.mat-raised-button {
  &:hover {
    background-color: $color-primary-light;
    &.mat-primary {
      background-color: $color-primary-grey;
    }
  }
}

a,
button {
  &.mat-button,
  &.mat-stroked-button,
  &.mat-flat-button,
  &.mat-raised-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    border-radius: 160px;
    padding: 0 20px;
  }
}

.info-snackbar {
  background-color: $color-primary-light;
  color: $color-primary-dark;
}

.success-snackbar {
  background-color: $color-green;
  color: $color-primary-dark;
}

.warning-snackbar {
  background-color: $color-warning;
  color: $color-primary-dark;
}

.error-snackbar {
  background-color: $color-error;
  color: $color-primary-light;
}

a.link:hover {
  cursor: pointer;
}

.sticky-top {
  // lower z-index so it does not interfere with mat tooltip
  z-index: 900;
}

mat-form-field {
  &.edit-field {
    * {
      cursor: pointer;
    }
  }

  mat-icon[matPrefix] {
    transform: translateY(6px);
    margin-right: 8px;
  }
}

.pe-none {
  pointer-events: none;
}

vi-quest-hint {
  position: absolute;
  padding-left: 4px;

  &:hover {
    cursor: pointer;
  }

  &.shown {
    mat-icon {
      color: $color-black;
      opacity: 1;
    }
  }

  mat-icon:hover {
    color: $color-black !important;
    opacity: 1;
  }

  mat-card.mat-card {
    background: $color-primary-lighter;
    min-width: 300px;
    position: relative;
    left: -200px;

    &:hover {
      background: $color-primary-lighter;
    }
  }
}

/* no arrow buttons (+/-) for <input type="number" /> */
input.mat-input-element::-webkit-outer-spin-button,
input.mat-input-element::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.mat-input-element[type='number'] {
  -moz-appearance: textfield;
}

mat-toolbar {
  background-color: $color-red !important;
}

.title {
  color: $color-white;
  font-weight: bold !important;
}

.country-selector {
  color: $color-white;
}
